.janeLogo {
  display: flex;
}

.janeLogo__xs {
  padding: 8px;
}

.janeLogo__sm {
  padding: 11px; /* stylelint-disable-line */
}

.janeLogo__md {
  padding: 15px; /* stylelint-disable-line */
}

.janeLogo__lg {
  padding: 30px; /* stylelint-disable-line */
}

.janeLogo__xl {
  padding: 60px; /* stylelint-disable-line */
}

/* Logo */

.janeLogo_logo {
  color: var(--colors-grays-black);
}

.janeLogo_logo__white {
  color: var(--colors-grays-white);
}

/* Sizes - Primary Logo */

.janeLogo_logo__primary_xs {
  height: 22px;
  width: 43px;
}

.janeLogo_logo__primary_sm {
  height: 40px;
  width: 78px;
}

.janeLogo_logo__primary_md {
  height: 56px;
  width: 108px;
}

.janeLogo_logo__primary_lg {
  height: 110px;
  width: 213px;
}

.janeLogo_logo__primary_xl {
  height: 224px;
  width: 434px;
}

/* Sizes - Secondary Logo */

.janeLogo_logo__secondary_xs {
  height: 13px;
  width: 43px;
}

.janeLogo_logo__secondary_sm {
  height: 24px;
  width: 91px;
}

.janeLogo_logo__secondary_md {
  height: 40px;
  width: 150px;
}

.janeLogo_logo__secondary_lg {
  height: 72px;
  width: 270px;
}

.janeLogo_logo__secondary_xl {
  height: 142px;
  width: 532px;
}
