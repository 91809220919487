.button {
  align-items: center;
  border-width: 0;
  cursor: pointer;
  height: 48px;
  margin-bottom: var(--margin-bottom);
  margin-left: var(--margin-left);
  margin-right: var(--margin-right);
  margin-top: var(--margin-top);
  min-width: 48px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: all 250ms;
}

.button:focus-visible {
  outline: none;
}

.button:hover::before,
.button:focus-visible::before {
  border-radius: inherit;
  content: '';
  position: absolute;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.buttonText {
  --btn-padding-x: 16px;
  display: inline-flex;
  padding: 0 var(--btn-padding-x);
  min-width: -moz-fit-content;
  min-width: fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.iconButton {
  --btn-icon-padding: 10px;
  min-width: auto;
  display: flex;
  height: var(--btn-icon-size);
  justify-content: center;
  padding: var(--btn-icon-padding);
  width: var(--btn-icon-size);
}

.button__full {
  width: 100%;
}

.button__disabled {
  opacity: 0.3;
  pointer-events: none;
}

.button_newTabLabel {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px; /* stylelint-disable-line */
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.button__loading {
  pointer-events: none;
}

.button_iconLabel {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.button_iconLabel__left {
  justify-content: flex-start;
}

.button_iconLabel__center {
  justify-content: center;
}

.button_iconLabel__right {
  justify-content: flex-end;
}

.button_label {
  display: flex;
  flex-direction: column;
}

.button_label__start {
  padding-left: 8px;
}

.button_label__end {
  padding-right: 8px;
}

/* Variants */

/* Variants: Primary */
.button__primary {
  background-color: var(--btn-primary-background-color);
  border-color: var(--btn-primary-border-color, inherit);
  border-style: solid;
  border-width: var(--btn-primary-border-width);
  color: var(--btn-primary-typography-color);
}

.button__primary:hover::before,
.button__primary:focus-visible::before {
  inset: calc(var(--btn-primary-border-width) * -1);
}

/* Variants: Primary Inverse */

.button__primary-inverse {
  background-color: var(--btn-primary-inverse-background-color);
  border-color: var(--btn-primary-inverse-border-color);
  border-style: solid;
  border-width: var(--btn-primary-inverse-border-width);
  color: var(--btn-primary-inverse-typography-color);
}

.button__primary-inverse:hover::before,
.button__primary-inverse:focus-visible::before {
  inset: calc(var(--btn-primary-inverse-border-width) * -1);
}

/* Variants: Secondary */

.button__secondary {
  background-color: var(--btn-secondary-background-color);
  border-color: var(--btn-secondary-border-color);
  border-style: solid;
  border-width: var(--btn-secondary-border-width);
  color: var(--btn-secondary-typography-color);
}

.button__secondary:hover::before,
.button__secondary:focus-visible::before {
  inset: calc(var(--btn-secondary-border-width) * -1);
}

/* Variants: Tertiary */

.button__tertiary {
  background-color: var(--btn-tertiary-background-color);
  border-color: var(--btn-tertiary-border-color);
  border-style: solid;
  border-width: var(--btn-tertiary-border-width);
  color: var(--btn-tertiary-typography-color);
}

.button__tertiary:hover::before,
.button__tertiary:focus-visible::before {
  inset: calc(var(--btn-tertiary-border-width) * -1);
}

/* Variants: Tertiary Selected */

.button__tertiary-selected {
  background-color: var(--btn-tertiary-selected-background-color);
  border-color: var(--btn-tertiary-selected-border-color);
  border-style: solid;
  border-width: var(--btn-tertiary-selected-border-width);
  color: var(--btn-tertiary-selected-typography-color);
}

.button__tertiary-selected:hover::before,
.button__tertiary-selected:focus-visible::before {
  inset: calc(var(--btn-tertiary-selected-border-width) * -1);
}

/* Variants: Destructive */

.button__destructive {
  background-color: var(--btn-destructive-background-color);
  border-color: var(--btn-destructive-border-color);
  border-style: solid;
  border-width: var(--btn-destructive-border-width);
  color: var(--btn-destructive-typography-color);
}

.button__destructive:hover::before,
.button__destructive:focus-visible::before {
  inset: calc(var(--btn-destructive-border-width) * -1);
}

/* Variants: Destructive Secondary */

.button__destructive-secondary {
  background-color: var(--btn-destructive-secondary-background-color);
  border-color: var(--btn-destructive-secondary-border-color);
  border-style: solid;
  border-width: var(--btn-destructive-secondary-border-width);
  color: var(--btn-destructive-secondary-typography-color);
}

.button__destructive-secondary:hover::before,
.button__destructive-secondary:focus-visible::before {
  inset: calc(var(--btn-destructive-secondary-border-width) * -1);
}

/* Variants: Minimal */

.button__minimal {
  background-color: var(--btn-minimal-background-color);
  border-color: var(--btn-minimal-border-color);
  border-style: solid;
  border-width: var(--btn-minimal-border-width);
  color: var(--btn-minimal-typography-color);
}

.button__minimal:hover::before,
.button__minimal:focus-visible::before {
  inset: calc(var(--btn-minimal-border-width) * -1);
}

/* Variants: Minimal Inverse */

.button__minimal-inverse {
  background-color: var(--btn-minimal-inverse-background-color);
  border-color: var(--btn-minimal-inverse-border-color);
  border-style: solid;
  border-width: var(--btn-minimal-inverse-border-width);
  color: var(--btn-minimal-inverse-typography-color);
}

.button__minimal-inverse:hover::before,
.button__minimal-inverse:focus-visible::before {
  inset: calc(var(--btn-minimal-inverse-border-width) * -1);
}

/* Sizes: Button */

/* Sizes: Small */

.button__small {
  border-radius: var(--btn-small-border-radius, var(--border-radius-lg));
  height: var(--btn-small-height, 32px);
  padding-left: var(--btn-small-padding-x, var(--btn-padding-x));
  padding-right: var(--btn-small-padding-x, var(--btn-padding-x));
}

.button__small__withSubLabel {
  height: calc(var(--btn-small-height, 32px) + 8px);
}

/* Sizes: Default */

.button__default {
  border-radius: var(--btn-default-border-radius, var(--border-radius-lg));
  height: var(--btn-default-height, 48px);
  padding-left: var(--btn-default-padding-x, var(--btn-padding-x));
  padding-right: var(--btn-default-padding-x, var(--btn-padding-x));
}

.button__default__withSubLabel {
  height: calc(var(--btn-default-height, 48px) + 8px);
}

/* Sizes: Large */

.button__large {
  border-radius: var(--btn-large-border-radius, var(--border-radius-lg));
  height: var(--btn-large-height, 72px);
  padding-left: var(--btn-large-padding-x, var(--btn-padding-x));
  padding-right: var(--btn-large-padding-x, var(--btn-padding-x));
}

.button__large__withSubLabel {
  height: calc(var(--btn-large-height, 72px) + 8px);
}

/* Sizes: Jumbo */

.button__jumbo {
  border-radius: var(--btn-jumbo-border-radius, var(--border-radius-lg));
  height: var(--btn-jumbo-height, 72px);
  padding-left: var(--btn-jumbo-padding-x, var(--btn-padding-x));
  padding-right: var(--btn-jumbo-padding-x, var(--btn-padding-x));
}

.button__jumbo__withSubLabel {
  height: calc(var(--btn-jumbo-height, 72px) + 8px);
}

/* Sizes: IconButton */

/* Sizes: Small */
.iconButton__small {
  --btn-icon-size: var(--btn-icon-small-height, 48px);
  border-radius: var(--btn-icon-small-border-radius, var(--border-radius-lg));
  padding-left: var(--btn-icon-small-padding-x, var(--btn-icon-padding));
  padding-right: var(--btn-icon-small-padding-x, var(--btn-icon-padding));
}

/* Sizes: Default */

.iconButton__default {
  --btn-icon-size: var(--btn-icon-default-height, 48px);
  border-radius: var(--btn-icon-default-border-radius, var(--border-radius-lg));
  padding-left: var(--btn-icon-default-padding-x, var(--btn-icon-padding));
  padding-right: var(--btn-icon-default-padding-x, var(--btn-icon-padding));
}

/* Sizes: Large */

.iconButton__large {
  --btn-icon-size: var(--btn-icon-large-height, 48px);
  border-radius: var(--btn-icon-large-border-radius, var(--border-radius-lg));
  padding-left: var(--btn-icon-large-padding-x, var(--btn-icon-padding));
  padding-right: var(--btn-icon-large-padding-x, var(--btn-icon-padding));
}

/* Sizes: Jumbo */

.iconButton__jumbo {
  --btn-icon-size: var(--btn-icon-jumbo-height, 48px);
  border-radius: var(--btn-icon-jumbo-border-radius, var(--border-radius-lg));
  padding-left: var(--btn-icon-jumbo-padding-x, var(--btn-icon-padding));
  padding-right: var(--btn-icon-jumbo-padding-x, var(--btn-icon-padding));
}
