.buttonToggleButton {
  background: transparent;
  border-radius: var(--border-radius-lg);
  text-align: center;
  cursor: pointer;
  height: 48px;
  width: auto;
  border: 2px solid transparent;
  outline: none;
  padding-left: 16px;
  padding-right: 16px;
  margin-right: 16px;
}

.buttonToggleButton:last-of-type {
  margin-right: 0;
}

/** Prop - full: true **/
.buttonToggleButton__full {
  width: 100%;
}

/** Prop - disabled: true **/
.buttonToggleButton:disabled {
  pointer-events: none;
  opacity: 0.3;
}

/** Prop - selected: true **/

.buttonToggleButton[data-selected='true'] {
  pointer-events: none;
}

/** Prop - variant: 'primary' **/
.buttonToggleButton__primary {
  color: var(--colors-grays-black);
}

.buttonToggleButton__primary:hover,
.buttonToggleButton__primary:focus-visible,
.buttonToggleButton__primary:active {
  background-color: var(--colors-grays-hover);
}

.buttonToggleButton__primary:disabled {
  color: var(--colors-grays-dark);
}

.buttonToggleButton__primary[data-selected='true'] {
  border: 2px solid var(--colors-primary-main);
}

/** Prop - variant: 'inverse' **/
.buttonToggleButton__inverse {
  color: var(--colors-grays-white);
}

.buttonToggleButton__inverse:hover,
.buttonToggleButton__inverse:focus-visible,
.buttonToggleButton__inverse:active {
  background-color: rgba(255, 255, 255, 0.1);
}

.buttonToggleButton__inverse:disabled {
  color: var(--colors-grays-ultralight);
}

.buttonToggleButton__inverse[data-selected='true'] {
  border: 2px solid var(--colors-grays-white);
}

/** Prop - variant: 'tertiary' **/
.buttonToggleButton__tertiary {
  color: var(--colors-grays-black);
  background-color: var(--colors-grays-ultralight);
}

.buttonToggleButton__tertiary:hover,
.buttonToggleButton__tertiary:focus-visible,
.buttonToggleButton__tertiary:active {
  background-color: var(--colors-grays-hover);
}

.buttonToggleButton__tertiary:disabled {
  color: var(--colors-grays-dark);
}

.buttonToggleButton__tertiary[data-selected='true'] {
  border: 2px solid var(--colors-primary-main);
}
