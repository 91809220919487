.loadingLabel {
  height: auto;
  margin-top: 0;
  overflow: hidden;
  visibility: initial;
  width: 100%;
}

.loadingLabel__loading {
  height: 0;
  visibility: hidden;
}

.loadingLabel_sublabel:not(.loadingLabel__loading) {
  margin-top: -5px; /* stylelint-disable-line */
}

@media (min-width: 1024px) {
  .loadingLabel_sublabel:not(.loadingLabel__loading) {
    margin-top: 0;
  }
}
