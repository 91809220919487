.drawerContent {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  overflow-x: hidden;
}

.drawerContent__fitScreen {
  height: 0;
  overflow-y: scroll;
}

.drawerContent__withPadding {
  padding-left: var(--drawer-mobile-padding-horiz);
  padding-right: var(--drawer-mobile-padding-horiz);
  padding-top: var(--drawer-padding-vert);
  padding-bottom: var(--drawer-padding-vert);
}

@media (min-width: 768px) {
  .drawerContent__withPadding {
    padding-left: var(--drawer-desktop-padding-horiz);
    padding-right: var(--drawer-desktop-padding-horiz);
  }
}

.drawerContentDivider {
  border: none;
  flex: 0 0 auto;
  height: 1px;
  background-color: var(--colors-grays-light);
  margin-left: calc(var(--drawer-mobile-padding-horiz) * -1);
  margin-right: calc(var(--drawer-mobile-padding-horiz) * -1);
  margin-top: var(--drawer-padding-vert);
  margin-bottom: var(--drawer-padding-vert);
}

@media (min-width: 768px) {
  .drawerContentDivider {
    margin-left: calc(var(--drawer-desktop-padding-horiz) * -1);
    margin-right: calc(var(--drawer-desktop-padding-horiz) * -1);
  }
}
