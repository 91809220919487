.selectField_select {
  --chevron-down-icon: ''; /* Required to pass stylelint check */
  -webkit-appearance: none;
  appearance: none;
  background-image: var(--chevron-down-icon);
  background-position: calc(100% - 10px) center;
  background-repeat: no-repeat;
  background-size: 24px;
  background-color: var(--colors-grays-white);
  border: 1px solid var(--colors-grays-light);
  cursor: pointer;
  box-shadow: var(--shadows-drop);
  padding: 12px 24px 12px 16px;
  position: relative;
  width: 100%;
}

.selectField_select:disabled {
  cursor: default;
}

.selectField_select:focus-visible,
.selectField_select:active:not(:disabled) {
  border: 1px solid var(--colors-primary-main);
}

.selectField_select:focus,
.selectField_select:focus-visible {
  outline: none;
}

.selectField_select__borderRadius_xs {
  border-radius: var(--border-radius-xs);
}

.selectField_select__borderRadius_sm {
  border-radius: var(--border-radius-sm);
}

.selectField_select__borderRadius_lg {
  border-radius: var(--border-radius-lg);
}
