.icon_container {
  align-items: center;
  cursor: inherit;
  display: flex;
  height: var(--icon-height);
  justify-content: center;
  outline: none;
  position: relative;
  margin-bottom: var(--margin-bottom);
  margin-left: var(--margin-left);
  margin-right: var(--margin-right);
  margin-top: var(--margin-top);
  text-align: center;
  width: var(--icon-width);
}

.icon_container:focus-visible::after {
  position: absolute;
  content: '';
  display: inherit;
  top: -4px;
  left: -4px;
  bottom: -4px;
  right: -4px;
  border: 2px solid var(--colors-primary-main);
  border-radius: var(--border-radius-lg);
  background-color: transparent;
}

.icon_container__pointer {
  cursor: pointer;
}

.icon {
  user-select: none;
  overflow: hidden;
  display: inline-block;
  text-align: center;
  flex-shrink: 0;
  height: 100%;
  width: 100%;
}

.rotate-left {
  transform: rotate(-90deg);
}

.rotate-right {
  transform: rotate(90deg);
}

.rotate-down {
  transform: rotate(180deg);
}

.icon-stroke-color rect {
  stroke: var(--icon-stroke-color, currentColor);
}

.icon-stroke-color circle {
  stroke: var(--icon-stroke-color, currentColor);
}

.icon-fill-color path:not([class*='Details']) {
  fill: var(--icon-fill-color, currentColor);
  fill-opacity: 1;
}
