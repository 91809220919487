.popover_divider {
  border: none;
  height: 1px;
  background-color: var(--colors-grays-light);
  margin: 24px -24px;

  @media (min-width: 768px) {
    margin: 16px -16px;
  }
}
