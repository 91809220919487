.resultListItem {
  list-style: none;
  transition: all 250ms;
}

.resultListItem mark {
  background-color: transparent;
  color: var(--colors-primary-main);
}

.resultListItem_buttonLabel {
  pointer-events: none;
}

.resultListItem_button {
  background: transparent;
  border-width: 0;
  color: inherit;
  cursor: pointer;
  display: block;
  height: 100%;
  margin: 0;
  padding: 12px 24px;
  outline: none;
  text-align: left;
  text-decoration: none;
  width: 100%;
}

.resultListItem_button:hover,
.resultListItem_button:focus,
.resultListItem_button:active {
  background-color: rgba(0, 0, 0, 0.05);
}

.resultListItem_checkbox {
  cursor: pointer;
}

.resultListItem_checkbox label {
  color: inherit;
  cursor: pointer;
  width: 100%;
}

.resultListItem_checkbox > :global(.reefer_fieldWrapper_labelContainer) {
  margin: 0;
  padding: 12px 24px;
}

.resultListItem_checkbox > :global(.reefer_fieldWrapper_labelContainer):hover,
.resultListItem_checkbox > :global(.reefer_fieldWrapper_labelContainer):active,
.resultListItem_checkbox > :global(.reefer_fieldWrapper_labelContainer):focus {
  background-color: rgba(0, 0, 0, 0.05);
}
