.popover_mobileOverlay {
  z-index: var(--layers-overlay);
  height: 100vh;
  background: rgba(0, 0, 0, 0.4);
  bottom: 0;
  left: 0;
  right: 0;
  position: var(--position);
}

@media (min-width: 768px) {
  .popover_mobileOverlay {
    display: none;
  }
}

.popover_container {
  margin-bottom: var(--margin-bottom);
  margin-left: var(--margin-left);
  margin-right: var(--margin-right);
  margin-top: var(--margin-top);
  width: var(--width);
}

@media (min-width: 768px) {
  .popover_container {
    position: relative;
  }
}

.popover_target {
  cursor: pointer;
  display: block;
  outline: none;
  position: relative;
}

@media (min-width: 768px) {
  .popover_target {
    position: relative;
  }
}

.popover_target:focus-visible > *:after {
  position: absolute;
  content: '';
  display: inherit;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  border-radius: inherit;
  background-color: var(--colors-grays-hover);
}

.popover_alignment {
  height: auto;
  background: transparent;
  position: absolute;
  bottom: unset;
  left: unset;
  right: unset;
  pointer-events: auto;
  width: auto;
}

.popover_alignment__info {
  width: -moz-fit-content;
  width: fit-content;
  min-width: 320px;
}

.popover_alignment__action {
  width: -moz-fit-content;
  width: fit-content;
  min-width: 240px;
}

.popover_alignment__noMinWidth {
  min-width: 0;
}

.popover_alignment__top {
  bottom: 100%;
  margin-top: 0;
}

.popover_alignment__left {
  left: 0;
}

.popover_alignment__right {
  right: 0;
}

.popover_alignment__center {
  left: 50%;
  transform: translate(-50%);
}

.popover_content {
  position: relative;
  bottom: unset;
  left: unset;
  right: unset;
  border-radius: var(--border-radius-lg);
  background-color: var(--colors-grays-white);
  box-shadow: var(--shadows-hard);
  z-index: var(--layers-popover);
  overflow-y: hidden;
}

.popover_content__alignment_top {
  margin-bottom: 16px;
}

.popover_content__alignment_bottom {
  margin-top: 16px;
}

.popover_content__mobile {
  @media (max-width: 768px) {
    position: relative;
    border-radius: var(--border-radius-lg) var(--border-radius-lg) 0 0;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    max-height: 90vh;
  }
}
