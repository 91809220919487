.bone {
  overflow: hidden;
  border-radius: var(--border-radius-lg, 24px);
  height: var(--height);
  width: var(--width);
  min-width: var(--min-width);
  margin-bottom: var(--margin-bottom);
  margin-left: var(--margin-left);
  margin-right: var(--margin-right);
  margin-top: var(--margin-top);
}

.bone-circular {
  border-radius: 50%;
}

.bone-sm {
  border-radius: var(--border-radius-sm, 16px);
}

.bone-lg {
  border-radius: var(--border-radius-lg, 24px);
}

.bone-fill {
  height: 100%;
  width: 100%;
  background-color: var(--colors-grays-light);
}

@keyframes animate-skeleton {
  0% {
    background-position: 100% 0;
  }

  100% {
    background-position: 0 0;
  }
}

.bone-fill-animate {
  animation: animate-skeleton 2s linear infinite;
  background-image: linear-gradient(
    90deg,
    var(--colors-grays-white),
    var(--colors-grays-light),
    var(--colors-grays-white),
    var(--colors-grays-light)
  );
  background-size: 300% 100%;
}
