/* Shadows */

:root,
:host {
  --shadows-soft: 0px 2px 16px rgba(0, 0, 0, 0.05);
  --shadows-hard: 0px 2px 16px rgba(0, 0, 0, 0.05),
    0px 0px 0px 1px rgba(0, 0, 0, 0.05);
  --shadows-item: 0px 2px 8px rgba(0, 0, 0, 0.05);
  --shadows-bar: 0px 0px 8px rgba(199, 161, 161, 0.1),
    0px 0px 0px 1px rgba(0, 0, 0, 0.05);
  --shadows-divider: inset 0px -1px 0px rgba(0, 0, 0, 0.1);
  --shadows-divider-top: 0px -1px 0px 0px rgba(0, 0, 0, 0.1);
  --shadows-drop: 0px 2px 4px rgba(0, 0, 0, 0.05);
  --shadows-inner: inset 0px 1px 4px rgba(0, 0, 0, 0.1);
}
