.link {
  color: var(--color);
  cursor: pointer;
  display: inline-block;
  height: fit-content;
  margin-bottom: var(--margin-bottom);
  margin-left: var(--margin-left);
  margin-right: var(--margin-right);
  margin-top: var(--margin-top);
  outline: none;
  width: fit-content;
}

.link:focus-visible {
  transition: none;
  border-bottom: 2px solid var(--colors-primary-main);
}

.link__router {
  text-decoration: none;
  outline: none;
  color: inherit;
}

.link__router:focus-visible {
  transition: none;
  border-bottom: 2px solid var(--colors-primary-main);
}

a.link {
  position: relative;
  text-decoration: none;
}

.underline {
  background-image: linear-gradient(
    to right,
    var(--color-underline) 15%,
    transparent 60%
  );
  background-position: bottom;
  background-repeat: repeat-x;
  background-size: 4px 1px;
  transition: all 250ms;
}

.underline:hover,
.underline:active,
.underline:focus {
  background-image: linear-gradient(
    to right,
    var(--color-underline) 50%,
    transparent 50%
  );
}

.new_tab_label {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px; /* stylelint-disable-line */
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.not_clickable {
  cursor: default;
}
