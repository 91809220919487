.footer {
  justify-self: flex-end;
  box-shadow: var(--shadows-divider-top);
}

.footer__withPadding {
  padding-left: var(--drawer-mobile-padding-horiz);
  padding-right: var(--drawer-mobile-padding-horiz);
  padding-top: var(--drawer-padding-vert);
  padding-bottom: var(--drawer-padding-vert);
}

@media (min-width: 768px) {
  .footer__withPadding {
    padding-left: var(--drawer-desktop-padding-horiz);
    padding-right: var(--drawer-desktop-padding-horiz);
  }
}
