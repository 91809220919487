.cardGroup {
  border: var(--cardGroup-border);
  border-radius: var(--border-radius-sm);
  box-shadow: var(--shadows-item);
  cursor: initial;
  display: inline-flex;
  flex-direction: var(--cardGroup-flex-direction);
  height: var(--cardGroup-height);
  overflow: hidden;
  height: fit-content;
  margin-bottom: var(--margin-bottom);
  margin-left: var(--margin-left);
  margin-right: var(--margin-right);
  margin-top: var(--margin-top);
  outline: none;
  position: relative;
  width: var(--cardGroup-width);
}

.cardGroup__flat {
  box-shadow: none;
}
