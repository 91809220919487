.popover_content {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: var(--max-height);
}

@media (min-width: 768px) {
  .popover_content {
    max-height: var(--max-height-desktop);
  }
}

.popover_content::-webkit-scrollbar {
  display: none;
}

.popover_content__padding {
  padding: 24px;
}

@media (min-width: 768px) {
  .popover_content__padding {
    padding: 16px;
  }
}
