.isHidden:not(:focus):not(:active) {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  opacity: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

@media (max-width: 768px) {
  .isHidden__tabletMax:not(:focus):not(:active) {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    opacity: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }
}

.hideFromScreenReader:not(:focus):not(:active) {
  display: none;
  visibility: hidden;
}
