.list {
  width: 100%;
  margin-bottom: var(--margin-bottom);
  margin-left: var(--margin-left);
  margin-right: var(--margin-right);
  margin-top: var(--margin-top);
  padding-bottom: var(--padding-bottom);
  padding-left: var(--padding-left);
  padding-right: var(--padding-right);
  padding-top: var(--padding-top);
}
