:root,
:host {
  --layers-toast: 100440;
  --layers-modal: 100430;
  --layers-drawer: 100420;
  --layers-header: 150;
  --layers-popover: 100;
  --layers-overlay: 10;
  --layers-visible: 5;
}
