.typeAheadResults_list {
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.typeAheadResults_list > li:last-of-type > button {
  padding-bottom: 20px;
}

.typeAheadResults_list::-webkit-scrollbar {
  display: none;
}
