.toast_overlay {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: var(--layers-toast);
  pointer-events: none;
}

.toast_container {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  right: 15px;
  overflow: hidden;
}

.toast_container__middle {
  align-items: center;
  right: 24px;
  left: 24px;
}

.toast {
  pointer-events: auto;
}

@keyframes toast-in-right {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0%);
  }
}

@keyframes toast-out-right {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(100%);
  }
}

@keyframes toast-in-middle-top {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0%);
  }
}

@keyframes toast-in-middle-bottom {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0%);
  }
}

@keyframes toast-out-middle {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.toast_animated__right_in {
  animation: toast-in-right 0.25s ease;
}

.toast_animated__right_out {
  animation: toast-out-right 0.25s ease;
}

.toast_animated__middle_top_in {
  animation: toast-in-middle-top 0.25s ease;
}

.toast_animated__middle_bottom_in {
  animation: toast-in-middle-bottom 0.25s ease;
}

.toast_animated__middle_out {
  animation: toast-out-middle 0.25s ease;
}
