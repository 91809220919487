.dateTimeField_input {
  background: var(--colors-grays-white);
  border: 1px solid var(--colors-grays-light);
  height: 48px;
  outline: none;
  padding: 12px 16px;
  position: relative;
  width: 100%;
}

@media (max-width: 768px) {
  .dateTimeField_input__mobile {
    background-color: transparent;
    border: none;
    border-radius: 0;
    height: 24px;
    padding: 2px 0;
  }
}

.dateTimeField_input::-webkit-calendar-picker-indicator {
  bottom: 0;
  height: auto;
  left: 0;
  background: transparent;
  position: absolute;
  color: transparent;
  top: 0;
  right: 0;
  width: auto;
}

.dateTimeField_input:disabled {
  opacity: 0.3;
}

.dateTimeField_input:active,
.dateTimeField_input:focus,
.dateTimeField_input:focus-visible {
  border-color: var(--colors-primary-main);
}

.dateTimeField_input:read-only,
.dateTimeField_input:disabled {
  border-color: var(--colors-grays-light);
}

.dateTimeField_input:read-only {
  background: var(--colors-grays-ultralight);
}

.dateTimeField_input__borderRadius_xs {
  border-radius: var(--border-radius-xs);
}

.dateTimeField_input__borderRadius_sm {
  border-radius: var(--border-radius-sm);
}

.dateTimeField_input__borderRadius_lg {
  border-radius: var(--border-radius-lg);
}
