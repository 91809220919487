/* Source Sans, also called Source Sans 3, default font -- Needed exclusively for Partner Hosted Embeds */
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap');

/* Jane default font, Source Sans Pro with alternate letters: "a", "g" and "l" */

@font-face {
  font-family: 'Jane Default';
  src: url('./fonts/source-sans-pro/SourceSansPro-Regular.woff2'),
    url('./fonts/source-sans-pro/SourceSansPro-Regular.woff');
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  -webkit-font-feature-settings: 'ss01' 1, 'ss02' 1, 'ss03' 1;
  -moz-font-feature-settings: 'ss01' 1, 'ss02' 1, 'ss03' 1;
  font-feature-settings: 'ss01' 1, 'ss02' 1, 'ss03' 1;
}

@font-face {
  font-family: 'Jane Default';
  src: url('./fonts/source-sans-pro/SourceSansPro-Semibold.woff2'),
    url('./fonts/source-sans-pro/SourceSansPro-Semibold.woff');
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  -webkit-font-feature-settings: 'ss01' 1, 'ss02' 1, 'ss03' 1;
  -moz-font-feature-settings: 'ss01' 1, 'ss02' 1, 'ss03' 1;
  font-feature-settings: 'ss01' 1, 'ss02' 1, 'ss03' 1;
}

@font-face {
  font-family: 'Jane Default';
  src: url('./fonts/source-sans-pro/SourceSansPro-Bold.woff2'),
    url('./fonts/source-sans-pro/SourceSansPro-Bold.woff');
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  -webkit-font-feature-settings: 'ss01' 1, 'ss02' 1, 'ss03' 1;
  -moz-font-feature-settings: 'ss01' 1, 'ss02' 1, 'ss03' 1;
  font-feature-settings: 'ss01' 1, 'ss02' 1, 'ss03' 1;
}

/* Euclid, Jane Branded font */

@font-face {
  font-family: 'Euclid Circular B';
  src: url('./fonts/euclid-circular-b/EuclidCircularB-Regular.woff2'),
    url('./fonts/euclid-circular-b/EuclidCircularB-Regular.woff');
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Euclid Circular B';
  src: url('./fonts/euclid-circular-b/EuclidCircularB-Semibold.woff2'),
    url('./fonts/euclid-circular-b/EuclidCircularB-Semibold.woff');
  font-style: normal;
  font-weight: 600;
  font-display: swap;
}
