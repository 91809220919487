.partnerLogo {
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  flex-shrink: 0;
  border-radius: 50%;
  background-color: var(--colors-grays-white);
}

.partnerLogo__xxs {
  background-size: 32px;
  height: 32px;
  width: 32px;
}

.partnerLogo__xs {
  background-size: 48px;
  height: 48px;
  width: 48px;
}

.partnerLogo__sm {
  background-size: 64px;
  height: 64px;
  width: 64px;
}

.partnerLogo__md {
  background-size: 72px;
  height: 72px;
  width: 72px;
}

.partnerLogo__lg {
  background-size: 96px;
  height: 96px;
  width: 96px;
}

.partnerLogo__xl {
  background-size: 120px;
  height: 120px;
  width: 120px;
}
