.tag {
  align-items: center;
  background-color: var(--tag-background-color);
  border-radius: var(--border-radius-lg);
  color: var(--tag-color);
  display: flex;
  height: fit-content;
  margin-bottom: var(--margin-bottom);
  margin-left: var(--margin-left);
  margin-right: var(--margin-right);
  margin-top: var(--margin-top);
  padding-bottom: var(--padding-bottom);
  padding-left: var(--padding-left);
  padding-right: var(--padding-right);
  padding-top: var(--padding-top);
  width: fit-content;
}

.tag__small {
  border-radius: var(--border-radius-sm);
}
