.switchField_input {
  appearance: none;
  cursor: pointer;
  height: 24px;
  outline: none;
  position: relative;
  width: 40px;
  z-index: 1;
}

.switchField_input:disabled {
  cursor: default;
}

.switchField_input:focus-visible:after {
  background-color: transparent;
  border: 1px solid var(--colors-primary-main);
  border-radius: 15px;
  bottom: -3px;
  content: '';
  display: inherit;
  left: -3px;
  position: absolute;
  right: -3px;
  top: -3px;
}

.switchField_input ~ div[role='presentation'] {
  background-color: var(--colors-grays-white);
  border: 2px solid var(--colors-grays-mid);
  border-radius: 12px;
  box-shadow: var(--shadows-inner);
  display: flex;
  height: 24px;
  padding: 4px;
  position: absolute;
  top: 0;
  width: 40px;
}

.switchField_input ~ div[role='presentation']:after {
  background-color: var(--colors-grays-mid);
  border-radius: 6px;
  content: '';
  display: block;
  height: 12px;
  width: 12px;
}

.switchField_input:checked ~ div[role='presentation'] {
  background-color: var(--colors-primary-main);
  border: 2px solid var(--colors-primary-main);
  box-shadow: none;
  justify-content: flex-end;
}

.switchField_input:checked ~ div[role='presentation']:after {
  background-color: var(--colors-grays-white);
}
