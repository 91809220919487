.textField_wrapper {
  align-items: center;
  background: var(--colors-grays-white);
  border: 1px solid var(--colors-grays-light);
  display: flex;
  height: 48px;
  margin: 0;
  outline: none;
  width: 100%;
  padding: 12px 16px;
}

.textField_wrapper:focus-within {
  border: 1px solid var(--colors-primary-main);
}

.textField_wrapper__readOnly {
  background: var(--colors-grays-ultralight);
  border-color: var(--colors-grays-light);
}

.textField_wrapper__readOnly:focus-within {
  border-color: var(--colors-grays-light);
}

.textField_wrapper__borderRadius_xs {
  border-radius: var(--border-radius-xs);
}

.textField_wrapper__borderRadius_sm {
  border-radius: var(--border-radius-sm);
}

.textField_wrapper__borderRadius_lg {
  border-radius: var(--border-radius-lg);
}

.textField_input {
  background: inherit;
  border: none;
  height: 100%;
  margin: 0;
  outline: none;
  padding: 0;
  width: 100%;
}

.textField_input::-webkit-outer-spin-button,
.textField_input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.textField_input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 100px white inset;
}

.textField_input:read-only {
  pointer-events: none;
}

.textField_input:disabled {
  background: none;
}

@media (max-width: 768px) {
  .textField_wrapper__mobile,
  .textField_wrapper__mobile:focus-within {
    background: transparent;
    border: none;
    border-radius: 0;
    height: 24px;
    padding: 2px 0px;
  }

  .textField_input__mobile {
    background: transparent;
  }
}
