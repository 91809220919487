@media (min-width: 768px) {
  .doubleSlider_fieldset {
    flex-direction: column;
  }
}

.doubleSlider_legend__isHidden:not(:focus):not(:active) {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  opacity: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.doubleSlider_helperText {
  order: 3;
}

@media (max-width: 768px) {
  .doubleSlider_helperText__labelHidden {
    margin-top: 16px;
  }
}

.doubleSlider_sliders__disabled {
  opacity: 0.3;
}
