.icon {
  display: inline-block;
}

.icon-edit {
  display: block;
  width: 20px;
  height: 20px;
  margin-right: 9px;
  background: url('shared/assets/icons/edit.svg') no-repeat center center;
}

.icon-sort {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 5px;
  border-top: 8px solid $main-green;
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
}

.icon-sort--active {
  border-top-color: $tangerine;
}

.icon-sort--reverse {
  border-top: 0;
  border-bottom: 8px solid $tangerine;
}
