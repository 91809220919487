.flex {
  align-content: var(--flex-align-content);
  align-items: var(--flex-align-items);
  align-self: var(--flex-align-self);
  display: flex;
  flex: var(--flex);
  flex-direction: var(--flex-direction);
  flex-wrap: var(--flex-wrap);
  gap: var(--flex-gap);
  justify-content: var(--flex-justify-content);
  justify-items: var(--flex-justify-items);
  justify-self: var(--flex-justify-self);
}

.flex__inline {
  display: inline-flex;
}
