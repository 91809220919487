.breadcrumbs {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
}

.breadcrumbs li {
  list-style: none;
  cursor: inherit;
}
