.searchField_input {
  -webkit-appearance: none;
  background: var(--colors-grays-white);
  border: 1px solid var(--colors-grays-light);
  display: flex;
  height: 48px;
  margin: 0;
  outline: none;
  padding: 12px 48px;
  width: 100%;
}

.searchField_input__hideSearchIcon {
  padding-left: 12px;
}

.searchField_input__borderRadius_xs {
  border-radius: var(--border-radius-xs);
}

.searchField_input__borderRadius_sm {
  border-radius: var(--border-radius-sm);
}

.searchField_input__borderRadius_lg {
  border-radius: var(--border-radius-lg);
}

.searchField_input::-ms-clear {
  display: none;
}

.searchField_input::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.searchField_input:active,
.searchField_input:focus,
.searchField_input:focus-visible {
  border-color: var(--colors-primary-main);
}

.searchField_input:read-only,
.searchField_input:disabled {
  border-color: var(--colors-grays-light);
  pointer-events: none;
}

@media (max-width: 768px) {
  .searchField_input__mobileStyled {
    background-color: transparent;
    border: none;
    border-radius: 0;
    height: 24px;
    padding: 2px 0 2px 32px;
  }

  .searchField_input__hideSearchIcon__mobileStyled {
    padding-left: 0;
  }
}

.searchField_dismissButton {
  --dismiss-filled-icon: ''; /* Required to pass stylelint check */
  background-color: transparent;
  background-image: var(--dismiss-filled-icon);
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  height: 24px;
  margin-top: -12px;
  outline: none;
  position: absolute;
  right: 16px;
  top: 50%;
  width: 24px;
}

.searchField_dismissButton:focus-visible:after {
  content: '';
  display: inherit;
  left: -3px;
  bottom: -3px;
  position: absolute;
  border: 1px solid var(--colors-primary-main);
  background-color: transparent;
  top: -3px;
  border-radius: 50%;
  right: -3px;
}

.searchfield_searchIconWrapper {
  left: 16px;
}

@media (max-width: 768px) {
  .searchfield_searchIconWrapper__mobileStyled {
    left: 0px;
  }
}
