.image {
  border-radius: var(--image-border-radius);
  display: flex;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  height: var(--image-height);
  width: var(--image-width);
}

.image:before {
  position: absolute;
  content: '';
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.image__border {
  border: 1px inset rgba(0, 0, 0, 0.05);
}

.image_img {
  aspect-ratio: var(--image-aspect-ratio);
  height: 100%;
  object-fit: var(--image-object-fit);
  object-position: var(--image-object-position);
  width: 100%;
}
