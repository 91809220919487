@keyframes wavvy {
  0% {
    left: 0px;
  }
  100% {
    left: var(--wavvy-length);
  }
}

.loading__container {
  --loading-height-xs: 16px;
  --loading-height-sm: 24px;
  --loading-height-lg: 36px;
  --loading-width-xs: 24px;
  --loading-width-sm: 48px;
  --loading-width-lg: 72px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
}

.loading__container--xs {
  width: var(--loading-width-xs);
}

.loading__container--sm {
  width: var(--loading-width-sm);
}

.loading__container--lg {
  width: var(--loading-width-lg);
}

.loading__animated {
  position: relative;
  width: 100%;
}

.loading__animated--xs {
  --wavvy-length: calc(0px - var(--loading-width-xs));
  animation: wavvy 800ms linear infinite;
}

.loading__animated--sm {
  --wavvy-length: calc(0px - var(--loading-width-sm));
  animation: wavvy 800ms linear infinite;
}

.loading__animated--lg {
  --wavvy-length: calc(0px - var(--loading-width-lg));
  animation: wavvy 800ms linear infinite;
}

.loading__wavvy {
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: 0 50%;
  mask-position: 0 50%;
  -webkit-mask-image: url('../../../../../../../shared/assets/reefer/loading/wavvy.svg');
  mask-image: url('../../../../../../../shared/assets/reefer/loading/wavvy.svg');
  width: calc(100% + 100px);
  -webkit-mask-image: var(--webkit-mask-image);
}

.loading__wavvy--xs {
  -webkit-mask-size: calc(var(--loading-width-xs) * 2);
  mask-size: calc(var(--loading-width-xs) * 2);
  height: var(--loading-height-xs);
}

.loading__wavvy--sm {
  -webkit-mask-size: calc(var(--loading-width-sm) * 2);
  mask-size: calc(var(--loading-width-sm) * 2);
  height: var(--loading-height-sm);
}

.loading__wavvy--lg {
  -webkit-mask-size: calc(var(--loading-width-lg) * 2);
  mask-size: calc(var(--loading-width-lg) * 2);
  height: var(--loading-height-lg);
}

.loading__wavvy--purple {
  background-color: var(--colors-primary-main);
}

.loading__wavvy--black {
  background-color: var(--colors-grays-black);
}

.loading__wavvy--white {
  background-color: var(--colors-grays-white);
}
