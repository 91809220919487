.cardContent {
  background-color: var(--cardContent-background-color);
  border: var(--cardContent-border);
  border-width: var(--cardContent-border-width);
  color: var(--cardContent-color);
  height: var(--cardContent-height);
  padding: 16px;
  outline: none;
  width: var(--cardContent-width);
}

.cardContent__clickable {
  cursor: pointer;
}

.cardContent__clickable:focus-visible {
  background: radial-gradient(
    transparent,
    transparent,
    var(--colors-grays-ultralight)
  );
  transition: 250ms all;
}
