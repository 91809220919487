.potency-group {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.potency-badge {
  align-items: center;
  background-color: $white;
  border-radius: 3px;
  border: solid 1px $gray-light;
  color: $gray-dark;
  display: flex;
  flex-direction: row;
  font-size: $body-text-size-micro;
  font-weight: bold;
  height: 20px;
  justify-content: flex-start;
  letter-spacing: 1px;
  text-transform: uppercase;
  width: 90px;

  &:first-child {
    margin-bottom: 3px;
    margin-right: 3px;
  }
}

.potency-badge__label {
  align-items: center;
  border-right: solid 1px $gray-light;
  display: flex;
  height: 100%;
  justify-content: center;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 2px;
}

.potency-badge__dots {
  display: flex;
  flex: 1;
  height: 100%;
}

.potency-badge__dot {
  align-items: center;
  border-right: solid 1px $gray-light;
  display: flex;
  flex: 1;
  height: 100%;
  justify-content: center;

  &:last-child {
    border-right: 0;
  }

  .svg-icon {
    fill: $gray-heavy;
    height: 3px;
    width: 3px;
  }
}

.potency-badge__percentage {
  flex: 1;
  text-align: center;
  padding-top: 2px;
}
