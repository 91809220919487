/* Overlay */

.ReactModal__Overlay {
  position: fixed;
  background: rgba(0, 0, 0, 0.4);
  bottom: 0;
  left: 0;
  opacity: 0;
  right: 0;
  top: 0;
  transition: opacity 200ms ease;
  z-index: var(--layers-modal);
}

.ReactModal__Overlay--isOpen {
  opacity: 1;
}

.ReactModal__Overlay--isClosed {
  opacity: 0;
}

/* Content */

.ReactModal__Content {
  --modal-standard-size: 896px;
  --modal-mobile-max-size: calc(100% - 48px);
  --modal-desktop-max-size: calc(100% - 128px);
  --modal-mobile-max-height: calc(
    var(--modal-mobile-max-size) - var(--keyboard-height)
  );
  --modal-desktop-max-height: calc(
    var(--modal-desktop-max-size) - var(--keyboard-height)
  );

  background-color: var(--modal-background-color);
  border-radius: var(--border-radius-lg);
  color: var(--modal-color);
  display: flex;
  flex: auto;
  flex-direction: column;
  left: 50%;
  min-width: 300px;
  overflow: var(--modal-overflow);
  position: absolute;
  top: var(--modal-top);
  transform: var(--modal-transform);
  -webkit-overflow-scrolling: touch;
}

.ReactModal__Content:focus-visible,
.ReactModal__Content:focus {
  outline: none;
}

/* Variants */

/* Dialogue */
.dialogue {
  height: auto;
  max-height: var(--modal-mobile-max-height);
  max-width: var(--modal-mobile-max-size);
  width: auto;
}

@media (min-width: 1024px) {
  .dialogue {
    max-height: var(--modal-desktop-max-height);
    max-width: var(--modal-desktop-max-size);
  }
}

/* Flex */

.flex {
  height: var(--modal-mobile-max-size);
  max-height: var(--modal-mobile-max-height);
  width: var(--modal-mobile-max-size);
}

@media (min-width: 1024px) {
  .flex {
    height: var(--modal-desktop-max-size);
    max-height: var(--modal-desktop-max-height);
    width: var(--modal-desktop-max-size);
  }
}

/* Full Screen */

.full-screen {
  border-radius: 0 !important;
  height: 100%;
  max-height: calc(100% - var(--keyboard-height));
  width: 100%;
}

/* Standard */

.standard {
  height: var(--modal-standard-size);
  width: var(--modal-standard-size);
  max-height: var(--modal-mobile-max-height);
  max-width: var(--modal-mobile-max-size);
}

@media (min-width: 1024px) {
  .standard {
    max-height: var(--modal-desktop-max-height);
    max-width: var(--modal-standard-size);
  }
}

/* Standard Dialogue */

.standard-dialogue {
  height: auto;
  width: var(--modal-standard-size);
  max-height: var(--modal-mobile-max-height);
  max-width: var(--modal-mobile-max-size);
}

@media (min-width: 1024px) {
  .standard-dialogue {
    max-height: var(--modal-desktop-max-height);
    max-width: var(--modal-standard-size);
  }
}
