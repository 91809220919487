.product-card__contents {
  display: flex;
  position: relative;
}

.product-card__stamp {
  flex: 0 0 70px;
  margin-right: 10px;
}

.product-card__icon {
  width: 70px;
  height: 40px;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  border-radius: 5px;
  margin-bottom: 1px;
}

.product-card__badges {
  @media (min-width: $min-width-screen-desktop) {
    display: flex;
    align-items: flex-end;
  }
}

.product-card__product-type-badge {
  width: 70px;
  display: inline-block;

  @media (min-width: $min-width-screen-desktop) {
    margin-right: 3px;
    margin-bottom: 3px;
  }
}

.product-card__details-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  @media only screen and (min-width: $min-width-screen-horiz-tablet) and (max-width: $max-width-screen-horiz-tablet) {
    max-width: 280px;
  }
}

.product-card__details {
  flex: 1 0;
}

.product-card__product-name {
  margin-bottom: 3px;
}

.product-card__potency-container {
  margin-top: 3px;
}
