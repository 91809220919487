.textFieldAutocomplete_container {
  position: relative;
}

.textFieldAutocomplete_alignment {
  background: transparent;
  height: auto;
  position: absolute;
  width: 100%;
  z-index: var(--layers-popover);
}

.textFieldAutocomplete_options {
  background-color: var(--colors-grays-white);
  border-radius: var(--border-radius-sm);
  box-shadow: var(--shadows-hard);
  overflow-y: hidden;
  position: relative;
  margin-top: 12px;
}

.textFieldAutocomplete_list {
  height: 100%;
  max-height: 40vh;
  overflow-y: auto;
}
