.numberField_wrapper {
  align-items: center;
  background-color: var(--colors-grays-white);
  border: 1px solid var(--colors-grays-light);
  display: flex;
  height: 48px;
  outline: none;
  padding: 12px 16px;
  width: 100%;
  transition: border 250ms;
}

.numberField_wrapper:focus-within {
  border-color: var(--colors-primary-main);
}

.numberField_wrapper__readOnly {
  background-color: var(--colors-grays-ultralight);
}

.numberField_wrapper__readOnly:focus-within {
  border-color: var(--colors-grays-light);
}

.numberField_wrapper__borderRadius_xs {
  border-radius: var(--border-radius-xs);
}

.numberField_wrapper__borderRadius_sm {
  border-radius: var(--border-radius-sm);
}

.numberField_wrapper__borderRadius_lg {
  border-radius: var(--border-radius-lg);
}

@media (max-width: 768px) {
  .numberField_wrapper__mobile {
    background-color: transparent;
    border: none;
    border-radius: 0;
    height: 24px;
    padding: 2px 0;
  }
}

.numberField_input {
  background-color: inherit;
  border: none;
  height: 100%;
  margin: 0;
  outline: none;
  padding: 0;
  width: 100%;
}

.numberField_input:disabled {
  opacity: 0.3;
}

.numberField_input:read-only {
  pointer-events: none;
}

.numberField_input[type='number'] {
  -moz-appearance: textfield;
}

.numberField_input::-webkit-outer-spin-button,
.numberField_input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@media (max-width: 768px) {
  .numberField_input__mobile {
    background-color: transparent;
  }
}
