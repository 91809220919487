.modalFooter {
  box-shadow: var(--shadows-divider-top);
  padding-top: var(--padding-top);
  padding-bottom: var(--padding-bottom);
  padding-left: var(--padding-left);
  padding-right: var(--padding-right);
  position: relative;
}

@media (min-width: 768px) {
  .modalFooter__full-screen {
    padding-left: 40px;
    padding-right: 40px;
  }
}
