/** Size **/

.gridItem {
  flex-grow: 0;
  padding-bottom: var(--gridItem-padding-bottom);
  padding-left: var(--gridItem-padding-left);
}

.gridItem__xs_size {
  flex-basis: var(--gridItem-size-xs);
}

.gridItem__xs_direction_row {
  max-width: var(--gridItem-size-xs);
}

.gridItem__xs_direction_col {
  max-height: var(--gridItem-size-xs);
}

@media (min-width: 375px) {
  .gridItem__sm_size {
    flex-basis: var(--gridItem-size-sm);
  }

  .gridItem__sm_direction_row {
    max-width: var(--gridItem-size-sm);
  }

  .gridItem__sm_direction_col {
    max-height: var(--gridItem-size-sm);
  }
}

@media (min-width: 768px) {
  .gridItem__md_size {
    flex-basis: var(--gridItem-size-md);
  }

  .gridItem__md_direction_row {
    max-width: var(--gridItem-size-md);
  }

  .gridItem__md_direction_col {
    max-height: var(--gridItem-size-md);
  }
}

@media (min-width: 1024px) {
  .gridItem__lg_size {
    flex-basis: var(--gridItem-size-lg);
  }

  .gridItem__lg_direction_row {
    max-width: var(--gridItem-size-lg);
  }

  .gridItem__lg_direction_col {
    max-height: var(--gridItem-size-lg);
  }
}

@media (min-width: 1440px) {
  .gridItem__xl_size {
    flex-basis: var(--gridItem-size-xl);
  }

  .gridItem__xl_direction_row {
    max-width: var(--gridItem-size-xl);
  }

  .gridItem__xl_direction_col {
    max-height: var(--gridItem-size-xl);
  }
}

/** Fill **/

.gridItem__xs_fill {
  flex-basis: auto;
  flex-grow: 1;
  max-width: none;
}

@media (min-width: 375px) {
  .gridItem__sm_fill {
    flex-basis: auto;
    flex-grow: 1;
    max-width: none;
  }
}

@media (min-width: 768px) {
  .gridItem__md_fill {
    flex-basis: auto;
    flex-grow: 1;
    max-width: none;
  }
}

@media (min-width: 1024px) {
  .gridItem__lg_fill {
    flex-basis: auto;
    flex-grow: 1;
    max-width: none;
  }
}

@media (min-width: 1440px) {
  .gridItem__xl_fill {
    flex-basis: auto;
    flex-grow: 1;
    max-width: none;
  }
}
