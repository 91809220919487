@keyframes fade-in {
  from,
  0%,
  to {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-out {
  from,
  0%,
  to {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes :global(reefer-drawer-slide-in-left) {
  from,
  0%,
  to {
    transform: translateX(-100%);
  }

  100%,
  to {
    transform: translateX(0);
  }
}

@keyframes :global(reefer-drawer-slide-out-left) {
  from,
  0%,
  to {
    transform: translateX(0);
  }

  100%,
  to {
    transform: translateX(-100%);
  }
}

@keyframes :global(reefer-drawer-slide-in-right) {
  from,
  0%,
  to {
    transform: translateX(100%);
  }

  100%,
  to {
    transform: translateX(0);
  }
}

@keyframes :global(reefer-drawer-slide-out-right) {
  from,
  0%,
  to {
    transform: translateX(0);
  }

  100%,
  to {
    transform: translateX(100%);
  }
}

@keyframes :global(reefer-drawer-slide-in-top) {
  from,
  0%,
  to {
    transform: translateY(-100%);
  }

  100%,
  to {
    transform: translateY(0);
  }
}

@keyframes :global(reefer-drawer-slide-out-top) {
  from,
  0%,
  to {
    transform: translateY(0);
  }

  100%,
  to {
    transform: translateY(-100%);
  }
}

@keyframes :global(reefer-drawer-slide-in-bottom) {
  from,
  0%,
  to {
    transform: translateY(100%);
  }

  100%,
  to {
    transform: translateY(0);
  }
}

@keyframes :global(reefer-drawer-slide-out-bottom) {
  from,
  0%,
  to {
    transform: translateY(0);
  }

  100%,
  to {
    transform: translateY(100%);
  }
}

.drawer {
  --drawer-desktop-padding-horiz: 40px;
  --drawer-mobile-padding-horiz: 24px;
  --drawer-padding-vert: 16px;
  z-index: var(--layers-drawer);
}

.rc-drawer-content {
  background-color: var(--drawer-background-color);
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.rc-drawer-mask-in {
  animation: fade-in 500ms linear;
  z-index: var(--layers-drawer);
}

.rc-drawer-mask-out {
  animation: fade-out 500ms linear;
  z-index: var(--layers-drawer);
}
