/* CSS Reset: https://www.joshwcomeau.com/css/custom-css-reset/ */
*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  margin: 0;
}

html,
body {
  height: 100%;
}

body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  font-family: 'Jane Default', 'Source Sans Pro', Arial, Helvetica, sans-serif;
}

input,
button,
textarea,
select {
  font: inherit;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}

fieldset,
legend,
ol,
ul {
  margin: 0;
  padding: 0;
}

li {
  list-style-type: none;
}

fieldset {
  border: none;
}

#root,
#__next {
  isolation: isolate;
}
