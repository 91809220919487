.banner {
  align-items: center;
  border-radius: var(--border-radius-sm);
  background-color: transparent;
  display: inline-flex;
  justify-content: space-between;
  margin-bottom: var(--margin-bottom);
  margin-left: var(--margin-left);
  margin-right: var(--margin-right);
  margin-top: var(--margin-top);
  padding: 16px;
  width: auto;
}

/* Full width */

.banner__full {
  width: 100%;
}

/* Variants */

.banner__error {
  background-color: var(--colors-error-light);
}

.banner__info {
  background-color: var(--colors-grays-ultralight);
}

.banner__success {
  background-color: var(--colors-success-light);
}

.banner_content {
  display: flex;
}
