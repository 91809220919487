.buttonToggle {
  display: flex;
  flex-direction: row;
  position: relative;
  width: auto;
  justify-content: flex-start;
}

.buttonToggle__full {
  width: 100%;
  justify-content: space-between;
}
