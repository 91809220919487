.modalContent {
  height: var(--modalContent-height);
  overflow-y: var(--modalContent-overflow-y);
}

.modalContent::-webkit-scrollbar {
  display: none;
}

.modalContent__withPadding {
  padding: 24px;
}

@media (min-width: 768px) {
  .modalContent__withPadding {
    padding: 40px;
  }
}

.content-divider {
  border: none;
}

.divider-padding {
  margin-bottom: 24px;
  margin-left: -24px;
  margin-right: -24px;
  margin-top: 24px;
}

@media (min-width: 768px) {
  .divider-padding {
    margin-bottom: 40px;
    margin-left: -40px;
    margin-right: -40px;
    margin-top: 40px;
  }
}
