.typography {
  color: var(--typography-color);
  font-family: var(--font-family-default);
  font-size: var(--typography-font-size-mobile);
  font-weight: var(--typography-font-weight-mobile);
  letter-spacing: var(--typography-letter-spacing-mobile);
  line-height: var(--typography-line-height-mobile);
  text-transform: var(--typography-text-transform-mobile);
  white-space: var(--typography-white-space);
}

@media (min-width: 1024px) {
  .typography {
    font-size: var(--typography-font-size-desktop);
    font-weight: var(--typography-font-weight-desktop);
    letter-spacing: var(--typography-letter-spacing-desktop);
    line-height: var(--typography-line-height-desktop);
    text-transform: var(--typography-text-transform-desktop);
  }
}

/* Modifiers */
.typography__bold {
  font-weight: var(--font-weight-semibold);
}

/* Specific weight for generic title-bold */
.typography__bold_generic {
  font-weight: 700;
}

.typography__branded {
  font-family: var(--font-family-branded);
}

.typography__strikethrough {
  text-decoration: line-through;
}

.typography__truncate {
  text-overflow: ellipsis;
}

/* Text Align */
.typography__text_align_left {
  text-align: left;
}

.typography__text_align_right {
  text-align: right;
}

.typography__text_align_center {
  text-align: center;
}

.typography__text_align_initial {
  text-align: initial;
}

.clamped-line {
  text-overflow: ellipsis;
  white-space: nowrap;
}

@supports (-webkit-line-clamp: 1) {
  .clamped-lines {
    -webkit-box-orient: vertical;
    -webkit-line-clamp: var(--typography-webkit-line-clamp);
    display: -webkit-box;
  }
}

.typography__default_font_settings {
  -webkit-font-feature-settings: 'ss01' 1, 'ss02' 1, 'ss03' 1;
  -moz-font-feature-settings: 'ss01' 1, 'ss02' 1, 'ss03' 1;
  font-feature-settings: 'ss01' 1, 'ss02' 1, 'ss03' 1;
}

/* Overflow Wrap */
.typography__overflow_wrap_anywhere {
  overflow-wrap: anywhere;
}

.typography__overflow_wrap_break-word {
  overflow-wrap: break-word;
}

.typography__overflow_wrap_inherit {
  overflow-wrap: inherit;
}

.typography__overflow_wrap_initial {
  overflow-wrap: initial;
}

.typography__overflow_wrap_normal {
  overflow-wrap: normal;
}

.typography__overflow_wrap_revert {
  overflow-wrap: revert;
}

.typography__overflow_wrap_revert-layer {
  overflow-wrap: revert-layer;
}

.typography__overflow_wrap_unset {
  overflow-wrap: unset;
}
