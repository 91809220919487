.textAreaField_textarea ~ div[role='presentation'] {
  background: var(--colors-grays-white);
  border: 1px solid var(--colors-grays-light);
}

.textAreaField_textarea__readOnly ~ div[role='presentation'] {
  background: var(--colors-grays-ultralight);
}

/**
 * A separate element for styling the `textarea` border.
 * When the border is applied to the `textarea` itself,
 * the resize control icon overflows the rounded corner.
 */

.textAreaField_textarea:active ~ div[role='presentation'],
.textAreaField_textarea:focus ~ div[role='presentation'],
.textAreaField_textarea:focus-visible ~ div[role='presentation'] {
  border-color: var(--colors-primary-main);
}

.textAreaField_textarea:read-only ~ div[role='presentation'],
.textAreaField_textarea:disabled ~ div[role='presentation'] {
  border-color: var(--colors-grays-light);
}

@media (max-width: 768px) {
  .textAreaField_textarea__mobile ~ div[role='presentation'] {
    background: transparent;
    border: none;
    border-radius: 0;
    height: 24px;
    padding: 2px 0;
  }
}

.textAreaField_textarea {
  /**
  * Add correct "padding" around the `textarea`, while correctly "padding"
  * the resize control icon, without causing the `textarea` to overflow its
  * container. (Padding styles don't affect that icon.)
  */
  background: transparent;

  /**
  * Text entry fields require `padding: 12px 16px`. Applying this spacing
  * with the border style above, displays the resize control icon with that spacing.
  * This looked poor. This padding keeps spacing consistent, without affecting
  * the resize control icon.
  */
  border: 12px solid transparent;
  min-height: 48px;
  outline: none;
  padding: 0 4px;
  position: relative;
  resize: none;
  width: 100%;
  z-index: 1;
}

.textAreaField_textarea__resize {
  resize: vertical;
}

@media (max-width: 768px) {
  .textAreaField_textarea__mobile {
    border: none;
    padding: 0;
  }
}

.textAreaField_textarea__borderRadius_xs {
  border-radius: var(--border-radius-xs);
}

.textAreaField_textarea__borderRadius_sm {
  border-radius: var(--border-radius-sm);
}

.textAreaField_textarea__borderRadius_lg {
  border-radius: var(--border-radius-lg);
}
