.drawerHeader {
  padding-left: var(--drawer-mobile-padding-horiz);
  padding-right: var(--drawer-mobile-padding-horiz);
  padding-top: var(--drawer-padding-vert);
  padding-bottom: var(--drawer-padding-vert);
  text-align: center;
}

@media (min-width: 768px) {
  .drawerHeader {
    padding-left: var(--drawer-desktop-padding-horiz);
    padding-right: var(--drawer-desktop-padding-horiz);
  }
}

.drawerHeader_withDivider {
  box-shadow: var(--shadows-divider);
}

.drawerHeader_title {
  margin-top: var(--drawer-padding-vert);
  margin-bottom: var(--drawer-padding-vert);
}
