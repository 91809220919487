/* Action */
.cardAction {
  margin-top: auto;
  width: 100%;
  padding: 0;
}

.cardAction_button {
  border-radius: 0;
}

.cardAction_button:before {
  border-radius: 0 !important;
}
