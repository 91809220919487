.listItem {
  list-style: none;
}

/** ALIGNMENT **/
.listItem__align_center {
  text-align: center;
  margin: 0 auto;
}

.listItem__align_right {
  text-align: right;
  margin: 0 0 0 auto;
}

.listItem__align_left {
  text-align: left;
}

/** SELECTED **/
.listItem__selected {
  background-color: var(--colors-grays-ultralight);
}

/** CLICKABLE **/
.listItem__clickable {
  cursor: pointer;
}
.listItem__clickable:hover,
.listItem__clickable:focus-within {
  background-color: var(--colors-grays-hover);
  outline: none;
}

.listItem__clickable.listItem__disableHover:hover,
.listItem__clickable.listItem__disableHover:focus-within {
  background-color: inherit;
}

/** LINKS **/
.listItem_link {
  color: inherit;
  display: block;
  height: 100%;
  outline: none;
  text-decoration: none;
  width: 100%;
  margin-bottom: var(--margin-bottom);
  margin-left: var(--margin-left);
  margin-right: var(--margin-right);
  margin-top: var(--margin-top);
  padding-bottom: var(--padding-bottom);
  padding-left: var(--padding-left);
  padding-right: var(--padding-right);
  padding-top: var(--padding-top);
}

.listItem_link:focus-within {
  background-color: var(--colors-grays-hover);
  border-bottom: unset;
  outline: none;
}
