.sliderField__variant_primary {
  --sliderField-color-main: var(--colors-primary-main);
  --sliderField-color-secondary: var(--colors-grays-white);
  --sliderField-color-track: var(--colors-grays-light);
}

.sliderField__variant_inverse {
  --sliderField-color-main: var(--colors-grays-white);
  --sliderField-color-secondary: var(--colors-primary-main);
  --sliderField-color-track: var(--colors-primary-dark);
}

.sliderField_spacer {
  background-color: transparent;
  height: 16px;
  pointer-events: none;
  touch-action: none;
  width: 100%;
  z-index: -1;
}

.sliderField_slider {
  border-radius: 12px;
  bottom: -2px;
  height: 2px;
  pointer-events: none;
  touch-action: none;
  position: relative;
  width: 100%;
}

.sliderField_slider__single,
.sliderField_slider__double__direction_left {
  background-color: var(--sliderField-color-main);
}

.sliderField_input {
  appearance: none;
  background: linear-gradient(
    to var(--sliderField-direction),
    transparent 0%,
    transparent var(--sliderField-percentageValue),
    var(--sliderField-color-track) var(--sliderField-percentageValue),
    var(--sliderField-color-track) 100%
  );
  border-radius: 12px;
  height: 2px;
  outline: none;
  position: relative;
  width: var(--sliderField-width);
  opacity: 1;
}

.sliderField_input::-moz-range-thumb {
  appearance: none;
  background: radial-gradient(
    circle,
    var(--sliderField-color-main) 0px,
    var(--sliderField-color-main) 25%,
    var(--sliderField-color-secondary) 25%,
    var(--sliderField-color-secondary) 100%
  );
  border: 2px solid var(--sliderField-color-main);
  border-radius: 16px;
  height: 32px;
  position: relative;
  width: 32px;
  z-index: 1;
}

.sliderField_input::-webkit-slider-thumb {
  appearance: none;
  background: radial-gradient(
    circle,
    var(--sliderField-color-main) 0px,
    var(--sliderField-color-main) 25%,
    var(--sliderField-color-secondary) 25%,
    var(--sliderField-color-secondary) 100%
  );
  border: 2px solid var(--sliderField-color-main);
  border-radius: 16px;
  height: 32px;
  position: relative;
  width: 32px;
  z-index: 1;
}

.sliderField_input:focus-visible:not(:disabled)::-moz-range-thumb,
.sliderField_input:hover:not(:disabled)::-moz-range-thumb {
  cursor: pointer;
  filter: brightness(90%);
}

.sliderField_input:focus-visible:not(:disabled)::-webkit-slider-thumb,
.sliderField_input:hover:not(:disabled)::-webkit-slider-thumb {
  cursor: pointer;
  filter: brightness(90%);
}
