a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

button {
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-size: inherit;
  padding: 0;
  margin: 0;
  color: inherit;
}

input,
textarea {
  margin: 0;
  border-radius: 3px;
  font-size: inherit;

  &:focus {
    outline-width: 0;
  }
}

hr {
  border-top: 0;
  border-color: $grey-d;
}

html,
body {
  margin: 0;
  min-width: 100%;
  padding: 0;
}
