.modalHeader {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  min-height: 80px;
  box-shadow: var(--shadows-divider);
  padding-top: var(--padding-top);
  padding-bottom: var(--padding-bottom);
  padding-left: var(--padding-left);
  padding-right: var(--padding-right);
}

@media (min-width: 768px) {
  .modalHeader__full-screen {
    padding-left: 40px;
    padding-right: 40px;
  }
}
