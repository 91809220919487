.svg-icon {
  width: 16px;
  height: 16px;
  fill: $gray-mid;
}

.svg-icon--small {
  height: 12px;
  width: 12px;
}

.svg-icon--xsmall {
  height: 9px;
  width: 9px;
}

.svg-icon--large {
  height: 26px;
  width: 26px;
}

.svg-icon--rotate-right {
  transform: rotate(90deg);
}

.svg-icon--rotate-left {
  transform: rotate(-90deg);
}

.svg-icon--flip {
  transform: rotate(-180deg);
}

.svg-icon--orange {
  fill: $orange-dark;
}

.svg-icon--green {
  fill: $green-dark;
}

.svg-icon--darkGray {
  fill: $gray-dark;
}

.svg-icon--yellow {
  fill: $yellow;
}
