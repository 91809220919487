.box {
  border-width: var(--box-border-width);
  bottom: var(--box-bottom);
  height: var(--box-height);
  left: var(--box-left);
  margin-bottom: var(--margin-bottom);
  margin-left: var(--margin-left);
  margin-right: var(--margin-right);
  margin-top: var(--margin-top);
  max-height: var(--box-max-height);
  max-width: var(--box-max-width);
  min-height: var(--box-min-height);
  min-width: var(--box-min-width);
  padding-bottom: var(--padding-bottom);
  padding-left: var(--padding-left);
  padding-right: var(--padding-right);
  padding-top: var(--padding-top);
  right: var(--box-right);
  top: var(--box-top);
  width: var(--box-width);
  z-index: var(--box-z-index);
}

/* Background Color */

.box__backgroundColor_primary {
  background-color: var(--colors-primary-main);
}
.box__backgroundColor_primary-light {
  background-color: var(--colors-primary-light);
}
.box__backgroundColor_primary-dark {
  background-color: var(--colors-primary-dark);
}
.box__backgroundColor_secondary {
  background-color: var(--colors-secondary-main);
}
.box__backgroundColor_secondary-light {
  background-color: var(--colors-secondary-light);
}
.box__backgroundColor_secondary-dark {
  background-color: var(--colors-secondary-dark);
}
.box__backgroundColor_info {
  background-color: var(--colors-info-main);
}
.box__backgroundColor_info-light {
  background-color: var(--colors-info-light);
}
.box__backgroundColor_info-dark {
  background-color: var(--colors-info-dark);
}
.box__backgroundColor_success {
  background-color: var(--colors-success-main);
}
.box__backgroundColor_success-light {
  background-color: var(--colors-success-light);
}
.box__backgroundColor_success-dark {
  background-color: var(--colors-success-dark);
}
.box__backgroundColor_error {
  background-color: var(--colors-error-main);
}
.box__backgroundColor_error-light {
  background-color: var(--colors-error-light);
}
.box__backgroundColor_error-dark {
  background-color: var(--colors-error-dark);
}
.box__backgroundColor_text-main {
  background-color: var(--colors-text-main);
}
.box__backgroundColor_text-inverse {
  background-color: var(--colors-text-inverse);
}
.box__backgroundColor_text-info {
  background-color: var(--colors-text-info);
}
.box__backgroundColor_text-light {
  background-color: var(--colors-text-light);
}
.box__backgroundColor_gold {
  background-color: var(--colors-brand-gold-main);
}
.box__backgroundColor_gold-light {
  background-color: var(--colors-brand-gold-light);
}
.box__backgroundColor_gold-dark {
  background-color: var(--colors-brand-gold-dark);
}
.box__backgroundColor_tangerine {
  background-color: var(--colors-brand-tangerine-main);
}
.box__backgroundColor_tangerine-light {
  background-color: var(--colors-brand-tangerine-light);
}
.box__backgroundColor_tangerine-dark {
  background-color: var(--colors-brand-tangerine-dark);
}
.box__backgroundColor_ember {
  background-color: var(--colors-brand-ember-main);
}
.box__backgroundColor_ember-light {
  background-color: var(--colors-brand-ember-light);
}
.box__backgroundColor_ember-dark {
  background-color: var(--colors-brand-ember-dark);
}
.box__backgroundColor_sunset {
  background-color: var(--colors-brand-sunset-main);
}
.box__backgroundColor_sunset-light {
  background-color: var(--colors-brand-sunset-light);
}
.box__backgroundColor_sunset-dark {
  background-color: var(--colors-brand-sunset-dark);
}
.box__backgroundColor_grape {
  background-color: var(--colors-brand-grape-main);
}
.box__backgroundColor_grape-light {
  background-color: var(--colors-brand-grape-light);
}
.box__backgroundColor_grape-dark {
  background-color: var(--colors-brand-grape-dark);
}
.box__backgroundColor_purple {
  background-color: var(--colors-brand-purple-main);
}
.box__backgroundColor_purple-light {
  background-color: var(--colors-brand-purple-light);
}
.box__backgroundColor_purple-dark {
  background-color: var(--colors-brand-purple-dark);
}
.box__backgroundColor_emerald {
  background-color: var(--colors-tertiary-emerald-main);
}
.box__backgroundColor_emerald-light {
  background-color: var(--colors-tertiary-emerald-light);
}
.box__backgroundColor_emerald-dark {
  background-color: var(--colors-tertiary-emerald-dark);
}
.box__backgroundColor_pacific {
  background-color: var(--colors-tertiary-pacific-main);
}
.box__backgroundColor_pacific-light {
  background-color: var(--colors-tertiary-pacific-light);
}
.box__backgroundColor_pacific-dark {
  background-color: var(--colors-tertiary-pacific-dark);
}
.box__backgroundColor_seafoam {
  background-color: var(--colors-tertiary-seafoam-main);
}
.box__backgroundColor_seafoam-light {
  background-color: var(--colors-tertiary-seafoam-light);
}
.box__backgroundColor_seafoam-dark {
  background-color: var(--colors-tertiary-seafoam-dark);
}
.box__backgroundColor_palm {
  background-color: var(--colors-tertiary-palm-main);
}
.box__backgroundColor_palm-light {
  background-color: var(--colors-tertiary-palm-light);
}
.box__backgroundColor_palm-dark {
  background-color: var(--colors-tertiary-palm-dark);
}
.box__backgroundColor_grays-white {
  background-color: var(--colors-grays-white);
}
.box__backgroundColor_grays-ultralight {
  background-color: var(--colors-grays-ultralight);
}
.box__backgroundColor_grays-light {
  background-color: var(--colors-grays-light);
}
.box__backgroundColor_grays-mid {
  background-color: var(--colors-grays-mid);
}
.box__backgroundColor_grays-dark {
  background-color: var(--colors-grays-dark);
}
.box__backgroundColor_grays-black {
  background-color: var(--colors-grays-black);
}
.box__backgroundColor_grays-hover {
  background-color: var(--colors-grays-hover);
}
.box__backgroundColor_system-positive-light {
  background-color: var(--colors-system-positive-light);
}
.box__backgroundColor_system-positive-dark {
  background-color: var(--colors-system-positive-dark);
}
.box__backgroundColor_system-positive-main {
  background-color: var(--colors-system-positive-main);
}
.box__backgroundColor_system-negative-light {
  background-color: var(--colors-system-negative-light);
}
.box__backgroundColor_system-negative-dark {
  background-color: var(--colors-system-negative-dark);
}
.box__backgroundColor_system-negative-main {
  background-color: var(--colors-system-negative-main);
}
.box__backgroundColor_transparent-black-five {
  background-color: var(--colors-transparent-black-five);
}
.box__backgroundColor_transparent-black-ten {
  background-color: var(--colors-transparent-black-ten);
}
.box__backgroundColor_transparent-black-twenty {
  background-color: var(--colors-transparent-black-twenty);
}
.box__backgroundColor_transparent-black-fifty {
  background-color: var(--colors-transparent-black-fifty);
}
.box__backgroundColor_transparent-white-ten {
  background-color: var(--colors-transparent-white-ten);
}
.box__backgroundColor_background {
  background-color: var(--colors-background);
}
.box__backgroundColor_default {
  background-color: var(--colors-default);
}
.box__backgroundColor_transparent {
  background-color: var(--colors-transparent);
}
.box__backgroundColor_inherit {
  background-color: var(--colors-inherit);
}

/* Border */

.box__border_primary {
  border-color: var(--colors-primary-main);
  border-style: solid;
}
.box__border_primary-light {
  border-color: var(--colors-primary-light);
  border-style: solid;
}
.box__border_primary-dark {
  border-color: var(--colors-primary-dark);
  border-style: solid;
}
.box__border_secondary {
  border-color: var(--colors-secondary-main);
  border-style: solid;
}
.box__border_secondary-light {
  border-color: var(--colors-secondary-light);
  border-style: solid;
}
.box__border_secondary-dark {
  border-color: var(--colors-secondary-dark);
  border-style: solid;
}
.box__border_info {
  border-color: var(--colors-info-main);
  border-style: solid;
}
.box__border_info-light {
  border-color: var(--colors-info-light);
  border-style: solid;
}
.box__border_info-dark {
  border-color: var(--colors-info-dark);
  border-style: solid;
}
.box__border_success {
  border-color: var(--colors-success-main);
  border-style: solid;
}
.box__border_success-light {
  border-color: var(--colors-success-light);
  border-style: solid;
}
.box__border_success-dark {
  border-color: var(--colors-success-dark);
  border-style: solid;
}
.box__border_error {
  border-color: var(--colors-error-main);
  border-style: solid;
}
.box__border_error-light {
  border-color: var(--colors-error-light);
  border-style: solid;
}
.box__border_error-dark {
  border-color: var(--colors-error-dark);
  border-style: solid;
}
.box__border_text-main {
  border-color: var(--colors-text-main);
  border-style: solid;
}
.box__border_text-inverse {
  border-color: var(--colors-text-inverse);
  border-style: solid;
}
.box__border_text-info {
  border-color: var(--colors-text-info);
  border-style: solid;
}
.box__border_text-light {
  border-color: var(--colors-text-light);
  border-style: solid;
}
.box__border_gold {
  border-color: var(--colors-brand-gold-main);
  border-style: solid;
}
.box__border_gold-light {
  border-color: var(--colors-brand-gold-light);
  border-style: solid;
}
.box__border_gold-dark {
  border-color: var(--colors-brand-gold-dark);
  border-style: solid;
}
.box__border_tangerine {
  border-color: var(--colors-brand-tangerine-main);
  border-style: solid;
}
.box__border_tangerine-light {
  border-color: var(--colors-brand-tangerine-light);
  border-style: solid;
}
.box__border_tangerine-dark {
  border-color: var(--colors-brand-tangerine-dark);
  border-style: solid;
}
.box__border_ember {
  border-color: var(--colors-brand-ember-main);
  border-style: solid;
}
.box__border_ember-light {
  border-color: var(--colors-brand-ember-light);
  border-style: solid;
}
.box__border_ember-dark {
  border-color: var(--colors-brand-ember-dark);
  border-style: solid;
}
.box__border_sunset {
  border-color: var(--colors-brand-sunset-main);
  border-style: solid;
}
.box__border_sunset-light {
  border-color: var(--colors-brand-sunset-light);
  border-style: solid;
}
.box__border_sunset-dark {
  border-color: var(--colors-brand-sunset-dark);
  border-style: solid;
}
.box__border_grape {
  border-color: var(--colors-brand-grape-main);
  border-style: solid;
}
.box__border_grape-light {
  border-color: var(--colors-brand-grape-light);
  border-style: solid;
}
.box__border_grape-dark {
  border-color: var(--colors-brand-grape-dark);
  border-style: solid;
}
.box__border_purple {
  border-color: var(--colors-brand-purple-main);
  border-style: solid;
}
.box__border_purple-light {
  border-color: var(--colors-brand-purple-light);
  border-style: solid;
}
.box__border_purple-dark {
  border-color: var(--colors-brand-purple-dark);
  border-style: solid;
}
.box__border_emerald {
  border-color: var(--colors-tertiary-emerald-main);
  border-style: solid;
}
.box__border_emerald-light {
  border-color: var(--colors-tertiary-emerald-light);
  border-style: solid;
}
.box__border_emerald-dark {
  border-color: var(--colors-tertiary-emerald-dark);
  border-style: solid;
}
.box__border_pacific {
  border-color: var(--colors-tertiary-pacific-main);
  border-style: solid;
}
.box__border_pacific-light {
  border-color: var(--colors-tertiary-pacific-light);
  border-style: solid;
}
.box__border_pacific-dark {
  border-color: var(--colors-tertiary-pacific-dark);
  border-style: solid;
}
.box__border_seafoam {
  border-color: var(--colors-tertiary-seafoam-main);
  border-style: solid;
}
.box__border_seafoam-light {
  border-color: var(--colors-tertiary-seafoam-light);
  border-style: solid;
}
.box__border_seafoam-dark {
  border-color: var(--colors-tertiary-seafoam-dark);
  border-style: solid;
}
.box__border_palm {
  border-color: var(--colors-tertiary-palm-main);
  border-style: solid;
}
.box__border_palm-light {
  border-color: var(--colors-tertiary-palm-light);
  border-style: solid;
}
.box__border_palm-dark {
  border-color: var(--colors-tertiary-palm-dark);
  border-style: solid;
}
.box__border_grays-white {
  border-color: var(--colors-grays-white);
  border-style: solid;
}
.box__border_grays-ultralight {
  border-color: var(--colors-grays-ultralight);
  border-style: solid;
}
.box__border_grays-light {
  border-color: var(--colors-grays-light);
  border-style: solid;
}
.box__border_grays-mid {
  border-color: var(--colors-grays-mid);
  border-style: solid;
}
.box__border_grays-dark {
  border-color: var(--colors-grays-dark);
  border-style: solid;
}
.box__border_grays-black {
  border-color: var(--colors-grays-black);
  border-style: solid;
}
.box__border_grays-hover {
  border-color: var(--colors-grays-hover);
  border-style: solid;
}
.box__border_system-positive-light {
  border-color: var(--colors-system-positive-light);
  border-style: solid;
}
.box__border_system-positive-dark {
  border-color: var(--colors-system-positive-dark);
  border-style: solid;
}
.box__border_system-positive-main {
  border-color: var(--colors-system-positive-main);
  border-style: solid;
}
.box__border_system-negative-light {
  border-color: var(--colors-system-negative-light);
  border-style: solid;
}
.box__border_system-negative-dark {
  border-color: var(--colors-system-negative-dark);
  border-style: solid;
}
.box__border_system-negative-main {
  border-color: var(--colors-system-negative-main);
  border-style: solid;
}
.box__border_transparent-black-five {
  border-color: var(--colors-transparent-black-five);
  border-style: solid;
}
.box__border_transparent-black-ten {
  border-color: var(--colors-transparent-black-ten);
  border-style: solid;
}
.box__border_transparent-black-twenty {
  border-color: var(--colors-transparent-black-twenty);
  border-style: solid;
}
.box__border_transparent-black-fifty {
  border-color: var(--colors-transparent-black-fifty);
  border-style: solid;
}
.box__border_transparent-white-ten {
  border-color: var(--colors-transparent-white-ten);
  border-style: solid;
}
.box__border_background {
  border-color: var(--colors-background);
  border-style: solid;
}
.box__border_default {
  border-color: var(--colors-default);
  border-style: solid;
}
.box__border_transparent {
  border-color: var(--colors-transparent);
  border-style: solid;
}
.box__border_inherit {
  border-color: var(--colors-inherit);
  border-style: solid;
}

.box__border_none {
  border: none;
}

/* Border Radius */

.box__borderRadius_xs {
  border-radius: var(--border-radius-xs);
}

.box__borderRadius_sm {
  border-radius: var(--border-radius-sm);
}

.box__borderRadius_lg {
  border-radius: var(--border-radius-lg);
}

.box__borderRadius_xl {
  border-radius: var(--border-radius-xl);
}

/* Overflow X */

.box__overflowX_auto {
  overflow-x: auto;
}

.box__overflowX_clip {
  overflow-x: clip;
}

.box__overflowX_hidden {
  overflow-x: hidden;
}

.box__overflowX_inherit {
  overflow-x: inherit;
}

.box__overflowX_scroll {
  overflow-x: scroll;
}

.box__overflowX_visible {
  overflow-x: visible;
}

/* Overflow Y */

.box__overflowY_auto {
  overflow-y: auto;
}

.box__overflowY_clip {
  overflow-y: clip;
}

.box__overflowY_hidden {
  overflow-y: hidden;
}

.box__overflowY_inherit {
  overflow-y: inherit;
}

.box__overflowY_scroll {
  overflow-y: scroll;
}

.box__overflowY_visible {
  overflow-y: visible;
}

/* Position */

.box__position_absolute {
  position: absolute;
}

.box__position_fixed {
  position: fixed;
}

.box__position_inherit {
  position: inherit;
}

.box__position_relative {
  position: relative;
}

.box__position_static {
  position: static;
}

.box__position_sticky {
  position: sticky;
}

/* Clickable */

.box__clickable {
  cursor: pointer;
}

.box__clickable:focus-visible {
  border: 2px solid var(--colors-grays-black);
  border-radius: var(--border-radius-lg);
  outline: none;
}
