/** Container **/
.fieldWrapper {
  --fieldWrapper-margin-bottom: '-1px';
}

.fieldWrapper__disabled {
  opacity: 0.3;
}

@media (max-width: 768px) {
  .fieldWrapper__isWrappingText {
    padding-bottom: 16px;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 16px;
    border-bottom: 1px solid var(--colors-grays-light);
  }

  .fieldwrapper:focus-within,
  .fieldWrapper__isWrappingText:focus-within {
    border-bottom: 2px solid var(--colors-primary-main);
    margin-bottom: var(--fieldWrapper-margin-bottom);
  }
}

/** Label **/
.fieldWrapper_label {
  display: block;
}

.fieldWrapper_label__position_r {
  margin-left: 12px;
}

.fieldWrapper_label__position_t {
  margin-bottom: 12px;
}

@media (max-width: 768px) {
  .fieldWrapper_label__position_t__mobileStyled {
    margin-bottom: 0;
  }

  .fieldWrapper_label__position_r__mobileStyled {
    margin-left: 8px;
  }
}

/** Helper text mobile **/

/** Does not need to be accessibly hidden:
  * the non-mobile helper text is accessibly hidden
  * thus always visiable to screen-readers
  */
@media (min-width: 768px) {
  .fieldWrapper_helperText__mobile {
    display: none;
  }
}
